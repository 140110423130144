import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'antd';
import Slider from 'react-slick';
import ProductCard from 'components/ProductCard';
import axios from 'axios';

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="arrow arrow__next" style={{ ...style }} onClick={onClick}>
      <Icon type="right" />
    </div>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="arrow arrow__prev" style={{ ...style }} onClick={onClick}>
      <Icon type="left" />
    </div>
  );
}

class Carousels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      categories: []
    };
  }

  componentDidMount() {
    this.getProducts();
    this.getCategories();
  }

  async getProducts() {
    const response = await axios.get(
      'https://el-proveedor.firebaseio.com/Productos.json?auth=2Y7n7SAQmhK4j4TnyWunF1Qwjb4uqONCZWfgyB0U'
    );
    this.setState({ products: Object.values(response.data) });
  }

  async getCategories() {
    const response = await axios.get(
      'https://el-proveedor.firebaseio.com/Categorias.json?auth=2Y7n7SAQmhK4j4TnyWunF1Qwjb4uqONCZWfgyB0U'
    );
    this.setState({ categories: Object.values(response.data) });
  }
  render() {
    const settings = {
      dots: false,
      dotsClass: 'hidden',
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1366,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            dots: true
          }
        },
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };
    return (
      <div className="categories--wrapper">
        {this.state.categories.map(category => {
          return (
            <div
              className="products__content__category"
              key={`products_category_${category.categoria}`}
            >
              <div className="category__header">
                <h3>{category.categoria}</h3>
                <Link to={`/productos/${category.categoria}`}>
                  <Button size="small">
                    Ver más
                    <Icon type="right" />
                  </Button>
                </Link>
              </div>
              <div className="category__list">
                <Slider {...settings}>
                  {this.state.products
                    .filter(product => product.categoria == category.categoria)
                    .map(product => {
                      return (
                        <ProductCard
                          data={product}
                          key={`product_${product.key}`}
                        />
                      );
                    })
                    .slice(0, 10)}
                </Slider>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Carousels;

import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: []
    };
  }

  componentDidMount() {
    this.getCategories();
  }

  async getCategories() {
    const response = await axios.get(
      'https://el-proveedor.firebaseio.com/Categorias.json?auth=2Y7n7SAQmhK4j4TnyWunF1Qwjb4uqONCZWfgyB0U'
    );
    this.setState({ categories: Object.values(response.data) });
  }

  handleCategories(param1, param2) {
    console.log(param2);
  }

  render() {
    const settings = {
      centerMode: true,
      focusOnSelect: true,
      dots: false,
      dotsClass: 'hidden',
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      variableWidth: true
    };
    return (
      <div className="categories">
        <ul className="products__categories">
          {this.state.categories.map(category => (
            <li className="categories__item" key={category.categoria}>
              <Link to={`/productos/${category.categoria}`}>
                {category.categoria}
              </Link>
            </li>
          ))}
        </ul>
        <div className="products__categories--responsive">
          <Slider {...settings} onSwipe={this.handleCategories}>
            {this.state.categories.map(category => (
              <div className="categories__item--responsive">
                <p>{category.categoria}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default Categories;

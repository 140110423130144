import React, { Component } from 'react';
import HeaderApp from 'components/HeaderApp';
import Separator from 'components/Separator';
import Brands from 'components/Brands';
import FooterApp from 'components/FooterApp';
import Wave, { ReactComponent } from 'assets/wave-alt.svg';
import Logo from 'assets/logo.png';
import { Link } from 'react-router-dom';

import './landing.css'

const svgReactElement = (
  <svg
    width="471px"
    height="309px"
    viewBox="0 0 471 309"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M279,308 C146.587823,307.785854 19.130402,317.491931 3,206 C-15.0201577,75.6167956 39.9627466,-40.5357318 197,14 C329.55635,59.5638385 470,34.0458062 470,181 C470,288.22563 394.749915,307.785854 279,308 Z"
        id="path-1"
      />
    </defs>
    <g
      id="Product"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Visitors" transform="translate(-206.000000, -1058.000000)">
        <g
          id="What-happens-when-a-visitor-arrives"
          transform="translate(206.000000, 945.000000)"
        >
          <g id="Video" transform="translate(0.000000, 113.000000)">
            <g id="Mask">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <clipPath id="videoMaskClipPath">
                <use
                  fill="#F6F6F7"
                  transform="translate(235.000000, 154.000000) scale(-1, 1) translate(-235.000000, -154.000000) "
                  xlinkHref="#path-1"
                />
              </clipPath>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const brandsData = [
  {
    url: require('../../assets/branch/1colombina.png')
  },
  {
    url: require('../../assets/branch/2super.png')
  },
  {
    url: require('../../assets/branch/3mondelez.png')
  },
  {
    url: require('../../assets/branch/4ferrero.png')
  },
  {
    url: require('../../assets/branch/5americandy.png')
  },
  {
    url: require('../../assets/branch/6confiteca.png')
  },
  {
    url: require('../../assets/branch/7aldor.png')
  },
  {
    url: require('../../assets/branch/8adro.png')
  },
  {
    url: require('../../assets/branch/9bienstar.png')
  },
  {
    url: require('../../assets/branch/10candiota.png')
  },
  // {
  //   url: require('../../assets/branch/11holadia.png')
  // },
  // {
  //   url: require('../assets/branch/12secretosdeltropico.png')
  // },
  {
    url: require('../../assets/branch/13pepsico.png')
  },
  {
    url: require('../../assets/branch/14ramo.png')
  },
  {
    url: require('../../assets/branch/15alpina.png')
  },
  {
    url: require('../../assets/branch/16bigcola.png')
  },
  {
    url: require('../../assets/branch/flips.png')
  }
];
const secundaryBrandsData = [
  // {
  //   url: require('../../assets/branch/garoto.png')
  // },
  {
    url: require('../../assets/branch/hersheys.png')
  },
  {
    url: require('../../assets/branch/kelloggs.png')
  },
  {
    url: require('../../assets/branch/m&m.png')
  },
  {
    url: require('../../assets/branch/milkyway.png')
  },
  {
    url: require('../../assets/branch/pringles.png')
  },
  {
    url: require('../../assets/branch/snikers.png')
  }
];
const waysToBuy = [
  {
    title: 'Tienda fisica',
    description:
      'Puedes visitarnos en la siguiente direccion ubicada en villavicencio.'
  },
  {
    title: 'Llamada telefonica',
    description:
      'Puedes comunicarte al 310 3371200 que es nuestro numero de celular o whatsapp.'
  },
  {
    title: 'Atención personalizada',
    description:
      'Te llevamos lo que necesitas a tu negocio. Es gratis si el pedido cuesta $100.000 COP o superior.'
  }
];
const features = [
  {
    title: 'Los mejores precios del mercado',
    description:
      'Realmente nos preocupamos de tu rentabilidad y por eso hemos hecho el mejor esfuerzo por ofrecerte los mejores precios y descuentos.',
    icon: require('../../Icons/Magic.svg')
  },
  {
    title: 'Productos de calidad',
    description:
      'Contamos con las marcas lideres de la industria tanto en confitería nacional como importada.',
    icon: require('../../Icons/Quality.svg')
  },
  {
    title: 'La mejor atencion',
    description:
      'Nuestros clientes son el alma de nuestra empresa, por eso nuestro equipo esta al servicio de brindarle la mejor atención y contarles de nuestras promociones.',
    icon: require('../../Icons/Time.svg')
  }
];

class Landing extends Component {
  render() {
    return (
      <div className="App">

        <header className="header">
          <figure className="header__brand">
            <img src={Logo} className="logo" />
          </figure>
          <div className="header__content">
            <h2 className="hero__title">
              Somos la tienda mayorista dulcera
              <br />
              mas completa de la ciudad
            </h2>
            <p className="hero__details">
              Tenemos infinidad de productos que podrás comprar
            </p>
          </div>
        </header>

        <section className="ways">
          <h2 className="ways__title">Maneras de comprar en el proveedor?</h2>
          <figure className="ways__photo">
            <img src={require('../../assets/photo_aldor.jpg')} />
            {svgReactElement}
          </figure>

          <ul className="ways__list">
            {waysToBuy.map((way, index) => {
              return (
                <li className="ways__item" key={`way${index}`}>
                  <div className="way_number">{index + 1}</div>
                  <h3>{way.title}</h3>
                  <p>{way.description}</p>
                </li>
              );
            })}
          </ul>
        </section>
        <section className="brands">
          <Separator label="Con la confianza de miles de empresas en todo el mundo." />
          <Brands brands={brandsData} rows="2" size="110" />
          <Brands brands={secundaryBrandsData} size="110" />
          <Separator />
          {/* <Brands brands={brandsData} size="60" /> */}
        </section>
        <section className="features">
          <div className="features__wrapper">
            <h2 className="features__title">3 Razones para preferirnos</h2>
            <ul className="features__list">
              {features.map((feature, index) => {
                return (
                  <li className="features__item" key={`feature${index}`}>
                    <h3>{feature.title}</h3>
                    <p>{feature.description}</p>
                    <img className="feature_icons" src={feature.icon} />
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        <section className="location">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6862.597594185685!2d-73.62351633993657!3d4.14045205783984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3e2e7a0396d27f%3A0x92d608de4b9b888f!2sEl+Proveedor!5e0!3m2!1sen!2sco!4v1552184086336"
            width="100%"
            height="450"
            frameborder="0"
            style={{ border: 0 }}
            allowfullscreen
          />
        </section>
        <FooterApp />
      </div>
    );
  }
}

export default Landing;

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Icon } from 'antd';
import Categories from './categories';
import Carousels from './carousels';
import List from './list';
import Logo from 'assets/logo.png';
import './index.css';

class Products extends Component {
  render() {
    return (
      <div className="products">
        <header className="App-header">
          <Link to="/">
            <img src={Logo} className="App-logo" />
          </Link>
        </header>
        <Router>
          <div className="products--wrapper">
            <Categories />
            <div className="products__content">
              <Route path="/productos" exact component={Carousels} />
              <Route path="/productos/:id" component={List} />
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

export default Products;

import React, { Component } from 'react';
import './index.css';

const ProductCard = props => {
  return (
    <div className="product_card">
      <img src={props.data.imagenURL} className="product_card__photo" />
      <h4>{ props.data.precio4 }</h4>
      <h4 className="product_card__name">{props.data.nombre}</h4>
    </div>
  );
};

export default ProductCard;

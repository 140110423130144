import React, { Component } from 'react';
import './index.css';
const Brands = props => {
  const listItems = props.brands.map((brand, index) => (
    <li className="brands__item" key={`brand${index}`}>
      <img
        src={brand.url}
        className="brands__item__img"
        style={{ width: `${props.size || 120}px` }}
      />
    </li>
  ));

  return (
    <ul className={`brands__list rows${props.rows || '1'}`}>{listItems}</ul>
  );
};

export default Brands;

import React, { Component } from "react"
import ProductCard from 'components/ProductCard';
import axios from 'axios';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  async getProducts() {
    const response = await axios.get(
      'https://el-proveedor.firebaseio.com/Productos.json?auth=2Y7n7SAQmhK4j4TnyWunF1Qwjb4uqONCZWfgyB0U'
    );
    this.setState({ products: Object.values(response.data) });
  }

  render() {
    return (
      <div className="list--wrapper">
        {
          this.state.products.filter(product => product.categoria == this.props.match.params.id).map(product => {
            return (
              <ProductCard data={product} />
            );
          }).slice(0, 10)
        }
      </div>
    )
  }
}

export default List

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import './index.css';
const FooterApp = props => {
  return (
    <footer className="footer">
      <div className="footer--wrapper">
        <div className="footer__actions">
          <Link to="/productos">
            <h2>Productos</h2>
          </Link>
          <div className="actions__call">
            <p>Nuestro numero de contacto es:<br/> <a href="tel:+573103371200">+57 310 337 1200</a></p>
            {/* <input type="tel" className="footer__input" /> */}
            {/* <button className="call__button">
              <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
            </button> */}
          </div>
        </div>
        <div className="footer__card">
          <div className="card__top">
            <span />
            <div className="redes__list">
              <a
                href="https://www.facebook.com/elproveedor10"
                target="_blank"
                className="redes__item"
              >
                <FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" />
              </a>
              <a
                href="https://www.instagram.com/elproveedor/"
                target="_blank"
                className="redes__item"
              >
                <FontAwesomeIcon icon={['fab', 'instagram']} size="lg" />
              </a>
              <a
                href="https://www.linkedin.com/company/elproveedor"
                target="_blank"
                className="redes__item"
              >
                <FontAwesomeIcon icon={['fab', 'linkedin']} size="lg" />
              </a>
            </div>
          </div>
          {/* <div className="card__middle">
            <ul className="menu__secundary_list">
              <li className="secundary_list__item">Ayuda</li>
              <li className="secundary_list__item">Comunidad</li>
              <li className="secundary_list__item">Precios</li>
              <li className="secundary_list__item">Trabaja con nosotros</li>
              <li className="secundary_list__item">Terminos de servicio</li>
              <li className="secundary_list__item">Testimonios</li>
              <li className="secundary_list__item">Contacto</li>
            </ul>
          </div> */}
          <div className="card__bottom">
            <p className="bottom__copy">&#169; elproveedor.com.co</p>
            <em className="bottom__desc">
              Todos los derechos reservados a Comercial El Proveedor S.A.S. Somos una empresa con mas de 10 años de experiencia.
            </em>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterApp;

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Landing from 'pages/Landing/index.js';
import Products from 'pages/Products/index.js';
import './App.css';

// import Mask from './assets/mask.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

library.add(faArrowRight);
library.add(faFacebookF);
library.add(faInstagram);
library.add(faLinkedin);
class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route path="/" exact component={Landing} />
          <Route path="/productos" component={Products} />
        </div>
      </Router>
    );
  }
}

export default App;
